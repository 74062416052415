<template>
    <footer class="footer">
        <ContentContainer class="footer__content">
            <div class="footer__content__left">
                <img class="footer__content__left__logo" src="../assets/svg/logo-footer.svg" alt="">
                <div class="footer__content__left__text">
                    {{ t("footer.text") }}
                </div>
                <div class="footer__content__left__text">
                    {{ t("footer.contact") }}
                </div>
                <a :href="'tel:' + contactDetails['contact_phone_' + langCode.value]" class="footer__content__left__tel">{{ contactDetails['contact_phone_' + langCode.value] }}</a>
                <a :href="'mailto:' + contactDetails['contact_email_' + langCode.value]" class="footer__content__left__mail">{{contactDetails['contact_email_' + langCode.value]}}</a>
                <a v-if="contactDetails['contact_email_2_' + langCode.value]" :href="'mailto:' + contactDetails['contact_email_2_' + langCode.value]" class="footer__content__left__mail">{{contactDetails['contact_email_2_' + langCode.value]}}</a>
                <a v-if="contactDetails['contact_email_3_' + langCode.value]" :href="'mailto:' + contactDetails['contact_email_3_' + langCode.value]" class="footer__content__left__mail">{{contactDetails['contact_email_3_' + langCode.value]}}</a>
            </div>
            <div class="footer__content__right">
                <div class="footer__content__right__links">
                    <a href="https://delayfix.com/documents/terms/Regulamin.pdf?v=1726491745895" class="footer__content__right__links__item">{{ t("footer.terms") }}</a>
                    <a href="https://delayfix.com/polityka-prywatnosci" class="footer__content__right__links__item">{{ t("footer.privacy") }}</a>
                </div>
                <div class="footer__content__right__info">
                    <p class="footer__content__right__info__address">
                        DelayFix S.A. <br>
                        ul. Ks. Ignacego Skorupki 5 <br>
                        00-546 Warszawa
                    </p>
                    <p class="footer__content__right__info__company">
                        Sąd Rejonowy dla m. st. Warszawy w Warszawie, 
                        XII Wydział Gospodarczy Krajowego Rejestru 
                        Sądowego, KRS 742480, NIP 5213681825
                    </p>
                    <p class="footer__content__right__info__capital">
                        {{ t("footer.capital") }} <br>
                        155 820,00 zł
                    </p>
                </div>
            </div>
            <div class="footer__copyrights">
                © 2024 DelayFix
            </div>
        </ContentContainer>
    </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ContentContainer from '@/layout/ContentContainer.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
const { t } = useI18n() 
const store = useStore()



const langCode = computed(() => {
  return useI18n().locale
})

const contactDetails = computed(() => {
  return store.state.contactDetails
})


</script>

<style lang="scss" scoped>

</style>