import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
            pageContent: {
            },
            contactDetails: {}
        }
    },
    mutations: {
        updatePageContent (state, data) {
            state.pageContent = data
        },
        updateContactDetails (state, data) {
            state.contactDetails = data
        }
    }
})

export default store
