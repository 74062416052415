<template>
    <div class="thank-you__wrapper">
        <div class="thank-you">
            <h2 class="thank-you__title">
                {{ t("thank-you.title") }}
            </h2>
            <div class="thank-you__icon__wrapper">
                <img src="../assets/svg/typVectorOne.svg" alt="" class="thank-you__icon">
                <div class="thank-you__arrow">
                    <div class="thank-you__arrow__line"></div>
                    <div class="thank-you__arrow__tip"></div>
                </div>
                <img src="../assets/svg/typVectorTwo.svg" alt="" class="thank-you__icon">
            </div>
            <p class="thank-you__paragraph">
                {{ t("thank-you.paragraph") }}
            </p>
            <button
                class="thank-you__button"
                @click="() => router.push('/?partner=' + partner)"
            >
                {{ content['thank_you_button_text_' + langCode.value] ?? t('thank-you.button') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import {useStore} from 'vuex';
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';
const { t } = useI18n() 

const router = useRouter()
const store = useStore()
const content = computed(() => {
  return store.state.pageContent
})

const langCode = computed(() => {
  return useI18n().locale
})

const partner = new URLSearchParams(window.location.search).get('partner')

</script>

<style lang="scss" scoped>

</style>