<template>
    <div
        class="hero"
        :style="[!isMobile ? heroBkgStyle : '']"
    >
        <ContentContainer class="hero__container">
            <div
                class="hero__title"
                :style="[isMobile ? heroBkgStyle : '']"
            >
                <h1 
                  class="hero__title__text"
                  :style="{'color': heroTextColor}"
                  >
                    {{ content['header_text_' + langCode.value] ?? ''}}
                </h1>
            </div>
            <Form class="hero__form"/>
        </ContentContainer>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex';
import ContentContainer from '@/layout/ContentContainer.vue'
import Form from '@/components/Form.vue';

const langCode = computed(() => {
  return useI18n().locale
})

const store = useStore()
const content = computed(() => {
  return store.state.pageContent
})

const isMobile = computed(() => window.innerWidth < 1024)

const heroTextColor = computed(() => {
  return store.state.pageContent.header_text_color
})

const heroBkgStyle = computed(() => {
  const headerColorRgb = hexToRgb(store.state.pageContent.header_color);
  const headerAlpha = store.state.pageContent.header_alpha / 100;
  const headerImage = store.state.pageContent?.header_image?.file;

  return {
    background: `linear-gradient(0deg, rgba(${headerColorRgb}, ${headerAlpha}) 0%,
                rgba(${headerColorRgb}, ${headerAlpha}) 100%),
                url('${headerImage}') 50% / cover no-repeat`
  };
});

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null; // Return null if the hex is invalid

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return `${r}, ${g}, ${b}`;  // Correctly format the RGB values
}

</script>

<style lang="scss" scoped>


</style>
