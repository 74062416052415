<template>
    <div>
        <ContentContainer class="why">
            <div class="why__text">
                <h2 class="why__text__title">
                    {{ content['why_delayfix_section_title_' + langCode.value] }}
                </h2>
                <p class="why__text__paragraph">
                    {{ content['why_delayfix_section_description_' + langCode.value] }}
                </p>
            </div>
            <img :src="content?.why_delayfix_section_image?.file ?? ''" alt="" class="why__img">
        </ContentContainer>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import ContentContainer from '@/layout/ContentContainer.vue';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
const store = useStore()

const content = computed(() => {
  return store.state.pageContent
})

const langCode = computed(() => {
  return useI18n().locale
})

</script>

<style lang="scss" scoped>

</style>