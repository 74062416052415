<template>
  <div class="page-content">
    <Hero/>
    <HowItWorks/>
    <Arguments/>
    <Why/>
    <Reviews/>
  </div>
</template>

<script setup>
import {getPageDetails, getContactDetails} from '@/service/pageInfo'
import Hero from '@/components/Hero.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import Arguments from '@/components/Arguments.vue';
import Why from '@/components/Why.vue';
import Reviews from '@/components/Reviews.vue';
import { useStore } from 'vuex';


const store = useStore()
const partner = new URLSearchParams(window.location.search).get('partner');


getPageDetails(partner)
    .then(response => {
      store.commit('updatePageContent', response.data)
    })
    .catch(error => console.log(error))

getContactDetails()
    .then(response => {
      store.commit('updateContactDetails', response.data)
    })
    .catch(error => console.log(error))

defineProps({
  pageData: Object
})
</script>

<style lang="scss" scoped>
.page-content {
  padding-top: 70px;
}
</style>
