import { createI18n } from 'vue-i18n'
import pl from './pl.js'
import en from './en.js'
import cs from './cs.js'

const i18n = createI18n({
    legacy: false,
    locale: 'pl',
    fallbackLocale: 'en',
    messages: {
        pl,
        en,
        cs
    }
})

export default i18n