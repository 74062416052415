import { createWebHistory, createRouter } from 'vue-router'

import Index from '@/pages/index.vue'
import thankYouPage from '@/pages/thankYouPage.vue'

const routes = [
  { path: '/', component: Index },
  { path: '/thank-you', component: thankYouPage},
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router