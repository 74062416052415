<template>
  <div class="content-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.content-wrapper {
  max-width: 1200px;
  margin: auto;
  overflow-x: hidden;
}
</style>
