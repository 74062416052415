<template>
    <Header />
    <RouterView />
    <Footer />
</template>

<script setup>

import Header from '@/layout/Header.vue'
import Footer from '@/layout/Footer.vue'
import { RouterView } from 'vue-router';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n() 

onMounted(() => {
    if(window.localStorage.getItem('language')) {
        locale.value = window.localStorage.getItem('language')
    }
})

</script>

<style lang="scss">
@import '@/assets/styles/main.scss';
</style>
