<template>
  <div class="form" id="form">
    <h3 class="form__title">
      {{ t("form.title") }}
    </h3>
    <p class="form__paragraph">
      {{ t("form.paragraph") }}
    </p>
    <form @submit.prevent="submitForm()">
      <BaseInput
          name="first_name"
          :label='t("form.name")'
          type="text"
          :placeholder='t("form.name")'
          :value="formValues.first_name"
          :error="errorName"
          @input-change="(val) => formValues.first_name = val"
          @input-blur="(target) => validateInput(target)"
      />
      <BaseInput
          name="last_name"
          :label='t("form.second_name")'
          type="text"
          :placeholder='t("form.second_name")'
          :value="formValues.last_name"
          :error="errorLastName"
          @input-change="(val) => formValues.last_name = val"
          @input-blur="(target) => validateInput(target)"
      />
      <BasePhone
          name="phone"
          :label='t("form.number")'
          :value="formValues.phone"
          :error="errorTel"
          @input-blur="(target) => validateTelInput(target)"
          @input-change="(val) => formValues.phone = val"
      />
      <BaseInput
          name="email"
          :label='t("form.mail.label")'
          type="email"
          :placeholder='t("form.mail.placeholder")'
          :value="formValues.email"
          :error="errorMail"
          @input-change="(val) => formValues.email = val"
          @input-blur="(target) => validateInput(target)"
      />
      <button
          v-if="!isComment"
          class="form__button"
          @click="isComment = true"
      >
        + {{ t("form.comment.btn") }}
      </button>
      <BaseInput
          v-if="isComment"
          :label='t("form.comment.label")'
          :label-sm='t("form.comment.label-sm")'
          type="textarea"
          :placeholder='t("form.comment.placeholder")'
          :value="formValues.comment"
          @input-change="(val) => {
                    commentLen = val.length;
                    formValues.comment = val;
                }"
          :error="errorTel"
      >
        <span class="input__el--textarea__len">{{ commentLen }}/500</span>
      </BaseInput>
      <BaseButton
          v-html='isLoading ? "<div class=loader></div>" : t("form.btn")'
      />
      <div v-if="formError" class="error" style="margin-top: 12px;">
        {{ formError }}
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, reactive} from 'vue'
import axios from 'axios';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import BaseInput from '@/components/Base/BaseInput.vue';
import BasePhone from '@/components/Base/BasePhone.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import {useI18n} from 'vue-i18n'

const {t, locale} = useI18n()
const router = useRouter()
const store = useStore()

const agencyId = computed(() => {
  return store.state.pageContent.external_id ?? ''
})

const isComment = ref(false);
const commentLen = ref(0);

const formValues = reactive({
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  comment: '',
  agency: agencyId,
  language: locale
})

const formError = ref('')
const errorName = ref('')
const errorLastName = ref('')
const errorMail = ref('')
const errorTel = ref('')
const isLoading = ref(false)

const validateInput = (target) => {
  if (target.type === 'text') {
    if (!target.value.length) {
      if (target.name === 'first_name') {
        errorName.value = 'form.error.empty'
      } else {
        errorLastName.value = 'form.error.empty'
      }
    } else {
      if (target.name === 'first_name') {
        errorName.value = ''
      } else {
        errorLastName.value = ''
      }
    }
  }

  if (target.type === 'email') {
    if (!target.value.length) {
      errorMail.value = 'form.error.empty'
    } else {
      let isValidMail = target.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isValidMail) {
        errorMail.value = 'form.error.invalid-email'
      } else {
        errorMail.value = ''
      }
    }
  }
}

function validateTelInput(target) {
  if (!target.value.length) {
    errorTel.value = 'form.error.empty'
  } else {
    let isValidTel = target.value.match(/\d{9,}/)
    if (!isValidTel) {
      errorTel.value = 'form.error.invalid-tel'
    } else {
      errorTel.value = ''
    }
  }
}

function submitForm() {
  for (const [key, value] of Object.entries(formValues)) {
    if (key == 'lastName') {
      if (value.length < 3) {
        errorLastName.value = 'form.error.empty'
        continue
      }
    } else if (key == 'name') {
      if (value.length < 3) {
        errorName.value = 'form.error.empty'
        continue
      }

    } else if (key == 'mail') {
      if (!value) {
        errorMail.value = 'form.error.empty'
        continue
      }
      let isValidMail = value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      if (!isValidMail) {
        errorMail.value = 'form.error.invalid-email'
        continue
      }
    } else if (key == 'phone') {
      if (!value) {
        errorTel.value = 'form.error.empty'
        continue
      }
      let isValidTel = value.match(/\d{9,}/)
      if (!isValidTel) {
        errorTel.value = 'form.error.invalid-tel'
        continue
      }
    }
  }

  if (errorMail.value || errorName.value || errorLastName.value || errorTel.value) {
    return
  }

  formError.value = ''
  isLoading.value = true
  axios.post('https://backend.delayfix.appwise.dev/api/recompensate/', {
    "agency": formValues.agency.toString(),
    "language": formValues.language === 'cz' ? formValues.language = 'cs' : formValues.language.toString(),
    "comment": formValues.comment.toString(),
    "first_name": formValues.first_name.toString(),
    "last_name": formValues.last_name.toString(),
    "phone": ('+' + formValues.phone).toString(),
    "email": formValues.email.toString(),
  })
      .then(() => {
        router.push('/thank-you?partner=' + new URLSearchParams(window.location.search).get('partner'))
      })
      .catch(err => {
        if(err.response.data.phone) {
          errorTel.value = 'form.error.invalid-tel'
        } else {
          formError.value = t("form.error.global")
        }
        isLoading.value = false
      })
}

</script>

<style lang="scss" scoped>

</style>
