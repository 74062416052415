<template>
  <div class="field-wrap">
    <label
        class="input"
        :class="{'input--error': error}"
    >
      <div class="input__label__wrapper">
        <span class="input__label" v-text="label"/>
        <span v-if="labelSm" class="input__label input__label--sm" v-text="labelSm"/>
      </div>
      <textarea
          v-if="type === 'textarea'"
          :name="props.name"
          :id="id"
          :value="value"
          :class="{ 'input__el--invalid': isInvalid }"
          :placeholder="placeholder"
          class="input__el input__el--textarea"
          @input="$emit('input-change', $event.target.value)"
          maxlength="500"
      >
        </textarea>
      <input
          v-else
          :id="id"
          :name="props.name"
          :value="value"
          :type="type"
          :placeholder="placeholder"
          class="input__el"
          @input="$emit('input-change', $event.target.value)"
          @blur="$emit('input-blur', $event.target)"
      />
      <div v-if="error" class="error">
        {{ t(error) }}
      </div>
    </label>
  </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const props = defineProps({
  value: {
    type: String,
    default: null
  },
  name: {
    type: String,
    default: null
  },
  id: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    default: ''
  },
  labelSm: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  }
})

</script>

<style lang="scss" scoped>
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &__label {
    color: #303030;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &--sm {
      color: #848A9E;
      margin-left: 5px;
    }

    &__wrapper {
      margin-bottom: 8px;
    }
  }

  &__el {
    height: 36px;
    padding: 0px 13px;
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    background: #F7F7F7;
    font-family: Inter;

    &::placeholder {
      color: #848A9E;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &--textarea {
      font-family: Inter;
      height: 100px;
      padding: 10px 13px;
      resize: none;
    }
  }

  &--error input {
    border: 2px solid red;
  }
}

.error {
  color: red;
  font-family: Inter;
  margin-top: 5px;
  font-size: 13px;
}

@media screen and (min-width: 1024px) {
  .input {
    margin-bottom: 25px;
  }
}
</style>
