<template>
  <div class="arguments__wrapper">
    <div class="arguments">
        <div class="arguments__text">
            <h2 class="arguments__text__title">
              {{ content['carousel_section_title_' + langCode.value] }}
            </h2>
            <p class="arguments__text__paragraph">
              {{ content['carousel_section_description_' + langCode.value] }}
            </p>
            <div class="arguments__items">
                <div class="arguments__items__item"
                    v-for="item in items"
                    :key="item.id"
                >
                    <img class="arguments__items__item__icon" :src="item.icon.file" alt="">
                    <h3 class="arguments__items__item__title">
                      {{ item['title_' + langCode.value] }}
                    </h3>
                    <p class="arguments__items__item__description">
                      {{ item['text_' + langCode.value] }}
                    </p>
                </div>
            </div>
        </div>
        <img class="arguments__img" :src="content?.carousel_section_image?.file ?? ''" alt="">
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex';
const store = useStore()

const langCode = computed(() => {
  return useI18n().locale
})

const content = computed(() => {
  return store.state.pageContent
})

const items = computed(() => store.state.pageContent.carousel_section_items)

// const items = ref([
//         {
//           id: 1,
//           title: "arguments.items[0].title",
//           text: "arguments.items[0].text",
//           icon: argumentsOne
//         },
//         {
//           id: 2,
//           title: "arguments.items[1].title",
//           text: "arguments.items[1].text",
//           icon: argumentsTwo
//         },
//         {
//           id: 3,
//           title: "arguments.items[2].title",
//           text: "arguments.items[2].text",
//           icon: argumentsThree
//         },
//         {
//           id: 4,
//           title: "arguments.items[3].title",
//           text: "arguments.items[3].text",
//           icon: argumentsFour
//         }
// ])
</script>

<style lang="scss" scoped>

</style>